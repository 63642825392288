import React from 'react';

import Exercise from './Exercise';

const LearnChordsComponent = () => {
  return (
    <>
      <Exercise />
    </>
  )
}

export default LearnChordsComponent;
