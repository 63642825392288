import React from 'react';

import MuiListItemText from '@material-ui/core/ListItemText';

const ListItemText = (props) => {
  return (
    <MuiListItemText {...props} />
  )
}

export default ListItemText;
