import React from 'react';

import MuiListItemIcon from '@material-ui/core/ListItemIcon';

const ListItemIcon = (props) => {
  return (
    <MuiListItemIcon {...props} />
  )
}

export default ListItemIcon;
