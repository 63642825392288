const keyboardMap = {
  'a': [144, 60],
  'w': [144, 61],
  's': [144, 62],
  'e': [144, 63],
  'd': [144, 64],
  'f': [144, 65],
  't': [144, 66],
  'g': [144, 67],
  'y': [144, 68],
  'h': [144, 69],
  'u': [144, 70],
  'j': [144, 71],
  'k': [144, 72],
  'o': [144, 73],
  'l': [144, 74],
  'p': [144, 75],
  ';': [144, 76],
  ':': [144, 76],
  '\'': [144, 77],
  '"': [144, 77],
  ']': [144, 78],
  '}': [144, 78],
  'shift': [176],
}

export default keyboardMap;
