import React from 'react';

import Text from '../common/mui/Text';

const HowToComponent = () => {
  return (
    <>
      <Text variant="h2" component="h1">
        Information Coming Soon
      </Text>
    </>
  )
}

export default HowToComponent;
